/** @format */

import { CountUp } from "countup.js";

const toggles = document.querySelectorAll('[data-toggle="price"]');
const DURATION = 1;

// Adding selectors for the buttons and labels
const monthlyButton = document.getElementById("monthlyButton");
const annualButton = document.getElementById("annualButton");
const monthlyLabel = document.getElementById("monthlyLabel");
const annualLabel = document.getElementById("annualLabel");

toggles.forEach((toggle) => {
	toggle.addEventListener("change", (e) => {
		const input = e.target;
		const isChecked = input.checked;

		// Show/hide buttons and underline labels based on the toggle state
		if (isChecked) {
			monthlyButton.style.display = "none";
			annualButton.style.display = "block";
			monthlyLabel.style.textDecoration = "none";
			annualLabel.style.textDecoration = "underline";
		} else {
			monthlyButton.style.display = "block";
			annualButton.style.display = "none";
			monthlyLabel.style.textDecoration = "underline";
			annualLabel.style.textDecoration = "none";
		}

		const target = input.dataset.target;
		const targets = document.querySelectorAll(target);

		targets.forEach((target) => {
			const annual = target.dataset.annual;
			const monthly = target.dataset.monthly;
			const options = target.dataset.options
				? JSON.parse(target.dataset.options)
				: {};

			options.startVal = isChecked ? monthly : annual; // Fixed this line to correctly reflect the start value based on isChecked
			options.duration = options.duration ? options.duration : DURATION;

			const countUp = new CountUp(
				target,
				isChecked ? annual : monthly,
				options
			); // Corrected logic to properly initialize CountUp with the correct target value

			countUp.start();
		});
	});
});
